<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:20px;">{{$t('ISSUE_SYSTEM_SERVICE')}}</div>
            </div>
            <!--data 数据源 v-model：对象行 -->
            <avue-crud :data="data" v-model="obj" :option="setData.option" :page.sync="page"
                       @current-change="currentChange" @row-update="editInfo">
                <template slot="menuLeft" style="width: calc(100% - 80px);">
                    <el-row :gutter="20">
                        <!--查询框-->
                        <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" class="fromOutDiv">
                            <el-input v-model.trim="issueCode" :placeholder="$t('Please enter the work order number')"
                                      clearable size="small"
                                      style="width:250px !important;marginRight: 5px;"></el-input>
                            <el-select v-model.trim="yxj" :placeholder="$t('Please enter a priority')" clearable
                                       size="small"
                                       style="width:200px !important;marginRight: 5px;">
                                <el-option
                                        v-for="item in priority"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                            <el-select v-model.trim="gdzt" :placeholder="$t('Please enter the work order status')"
                                       clearable size="small"
                                       style="width:250px !important;marginRight: 5px;">
                                <el-option
                                        v-for="item in status"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                            <el-select v-model.trim="wtlx" :placeholder="$t('Please enter the question type')" clearable size="small"
                                       style="width:200px !important;marginRight: 5px;">
                                <el-option
                                        v-for="item in typeList"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-col>
                        <!--按钮-->
                        <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4"
                                style=" text-align: right; align-self: flex-end;">
                            <el-button :title="$t('Refresh')" type="primary" icon="el-icon-search" size="mini" plain
                                       @click="refreshChange">{{$t('Refresh')}}
                            </el-button>
                        </el-col>
                    </el-row>
                </template>
                <!--操作栏-->
                <template slot-scope="{row,index}" slot="menu">
                    <el-button icon="el-icon-view" type="text" size="mini" :title="$t('View')"
                               @click="viewInfo(row,index)" v-if="false">{{$t('View')}}
                    </el-button>
                    <el-button icon="el-icon-s-comment" type="text" size="mini" :title="$t('Comment')"
                               @click="commentInfo(row,index)" v-if="row.status!==2">
                        {{$t('Comment')}}
                    </el-button>
                    <el-button icon="el-icon-s-promotion" type="text" size="mini" :title="$t('Care Of')"
                               @click="CareOfInfo(row,index)">{{$t('Care Of')}}
                    </el-button>
                </template>
            </avue-crud>
        </basic-container>
    </div>
</template>
<script>
    import {editKeFu, list} from "@/api/issue/issue";

    export default {
        data() {
            return {
                issueCode: "",
                yxj: "",
                gdzt: "",
                wtlx:"",
                obj: {},
                page: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background: false,
                    pageSize: 10
                },
                priority: [
                    {
                        label: 'low',
                        value: 0
                    },
                    {
                        label: 'middle',
                        value: 1
                    },
                    {
                        label: 'high',
                        value: 2
                    }
                ],
                //问题类型下拉框
                typeList: [
                    {
                        label: '业务分级咨询',
                        value: "业务分级咨询"
                    },
                    {
                        label: '技术咨询',
                        value: "技术咨询"
                    },
                    {
                        label: '用户数据信息咨询',
                        value: "用户数据信息咨询"
                    },
                ],
                status: [
                    {
                        label: '待处理',
                        value: 0
                    },
                    {
                        label: '处理中',
                        value: 1
                    },
                    {
                        label: '已完成',
                        value: 2
                    }

                ],
                data: [],
            }
        },
        computed: {
            setData() {
                return {
                    option: {
                        page: true,
                        align: 'center',
                        columnBtn: false,//列冻结、隐藏按钮
                        simplePage: false,
                        menuAlign: 'center',
                        addBtn: false,
                        refreshBtn: false,
                        delBtn: false,
                        editBtn: false,
                        column: [
                            {
                                label: '工单编号',
                                prop: 'issueCode',
                                display: false
                            },
                            {
                                label: '问题类型',
                                prop: 'issueType',
                                display: false
                            },
                            {
                                label: '优先级',
                                prop: 'priority',
                                type: "radio",
                                dicData: this.priority,
                                mock: {
                                    type: 'priority',
                                },
                                display: false
                            },
                            {
                                label: '备注',
                                prop: 'remark',
                                display: false
                            },
                            {
                                label: '客户姓名',
                                prop: 'customerName',
                                display: false
                            },
                            {
                                label: '区号',
                                prop: 'areaCode',
                                display: false
                            },
                            {
                                label: '联系电话',
                                prop: 'phone',
                                display: false
                            },
                            {
                                label: '邮箱账号',
                                prop: 'emailAccount',
                                display: false
                            },
                            {
                                label: '工单状态',
                                prop: 'status',
                                type: "radio",
                                dicData: this.status,
                                mock: {
                                    type: 'status',
                                },
                            },
                            // {
                            //     label: '父类菜单',
                            //     prop: 'parentMenu'
                            // },
                            // {
                            //     label: '子类菜单',
                            //     prop: 'subclassesMenu'
                            // },
                            // {
                            //     label: '问题描述',
                            //     prop: 'describe'
                            // },
                            // {
                            //     label: '服务人员',
                            //     prop: 'personnelRole'
                            // },
                            // {
                            //     label: '服务人员级别',
                            //     prop: 'personnelLevel'
                            // },
                        ]
                    }
                }
            }
        },
        created() {
            this.getList()
        },
        methods: {
            refreshChange() {
                this.getList()
            },
            //修改
            editInfo(row, index, done) {
                editKeFu(row).then((res) => {
                    done();
                    this.request.post(res);
                    this.$message({
                        type: "success",
                        message: res.data.msg,
                    });
                    this.getList();
                })
            },
            //查看
            viewInfo(row) {
                this.$router.push({path: '/help/issueAdd', query: {sign: 'view', issueCode: row.issueCode}})
            },
            //沟通
            commentInfo(row) {
                this.$router.push({
                    path: '/help/issueChat',
                    query: {sign: 'comment', issueCode: row.issueCode, roleSender: "0",id: row.id}
                })
            },
            //转交
            CareOfInfo(row) {
                this.$message('此功能该版本暂未开通')
            },
            getList() {
                const xx = {
                    pageNo: this.page.currentPage,
                    pageSize: this.page.pageSize,
                    total: this.page.total,
                    issueCode: this.issueCode,
                    priority: this.yxj,
                    status: this.gdzt,
                    issueType:this.wtlx,
                };
                list(xx).then(res => {
                    if (res.data.data == null) {
                        this.data = [];
                        this.page.total = 0;
                    } else {
                        this.page.total = res.data.data.total
                        this.data = res.data.data.items
                    }
                    console.log(res, 'res')
                }).catch(erro => {
                    console.log(erro);
                })
            },
            currentChange(val) {
                this.page.currentPage = val
                this.getList()
            },
        }
    }
</script>
<style scoped>
</style>
