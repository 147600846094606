var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c("div", { staticClass: "layTop" }, [
            _c(
              "div",
              {
                staticClass: "Title",
                staticStyle: { "margin-bottom": "20px" }
              },
              [_vm._v(_vm._s(_vm.$t("ISSUE_SYSTEM_SERVICE")))]
            )
          ]),
          _c(
            "avue-crud",
            {
              attrs: {
                data: _vm.data,
                option: _vm.setData.option,
                page: _vm.page
              },
              on: {
                "update:page": function($event) {
                  _vm.page = $event
                },
                "current-change": _vm.currentChange,
                "row-update": _vm.editInfo
              },
              scopedSlots: _vm._u([
                {
                  key: "menu",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    return [
                      false
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                icon: "el-icon-view",
                                type: "text",
                                size: "mini",
                                title: _vm.$t("View")
                              },
                              on: {
                                click: function($event) {
                                  return _vm.viewInfo(row, index)
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("View")) + "\n                "
                              )
                            ]
                          )
                        : _vm._e(),
                      row.status !== 2
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                icon: "el-icon-s-comment",
                                type: "text",
                                size: "mini",
                                title: _vm.$t("Comment")
                              },
                              on: {
                                click: function($event) {
                                  return _vm.commentInfo(row, index)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.$t("Comment")) +
                                  "\n                "
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-s-promotion",
                            type: "text",
                            size: "mini",
                            title: _vm.$t("Care Of")
                          },
                          on: {
                            click: function($event) {
                              return _vm.CareOfInfo(row, index)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("Care Of")) + "\n                "
                          )
                        ]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.obj,
                callback: function($$v) {
                  _vm.obj = $$v
                },
                expression: "obj"
              }
            },
            [
              _c(
                "template",
                {
                  staticStyle: { width: "calc(100% - 80px)" },
                  slot: "menuLeft"
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "fromOutDiv",
                          attrs: { xs: 24, sm: 16, md: 16, lg: 19, xl: 20 }
                        },
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "250px !important",
                              marginRight: "5px"
                            },
                            attrs: {
                              placeholder: _vm.$t(
                                "Please enter the work order number"
                              ),
                              clearable: "",
                              size: "small"
                            },
                            model: {
                              value: _vm.issueCode,
                              callback: function($$v) {
                                _vm.issueCode =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "issueCode"
                            }
                          }),
                          _c(
                            "el-select",
                            {
                              staticStyle: {
                                width: "200px !important",
                                marginRight: "5px"
                              },
                              attrs: {
                                placeholder: _vm.$t("Please enter a priority"),
                                clearable: "",
                                size: "small"
                              },
                              model: {
                                value: _vm.yxj,
                                callback: function($$v) {
                                  _vm.yxj =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "yxj"
                              }
                            },
                            _vm._l(_vm.priority, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-select",
                            {
                              staticStyle: {
                                width: "250px !important",
                                marginRight: "5px"
                              },
                              attrs: {
                                placeholder: _vm.$t(
                                  "Please enter the work order status"
                                ),
                                clearable: "",
                                size: "small"
                              },
                              model: {
                                value: _vm.gdzt,
                                callback: function($$v) {
                                  _vm.gdzt =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "gdzt"
                              }
                            },
                            _vm._l(_vm.status, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-select",
                            {
                              staticStyle: {
                                width: "200px !important",
                                marginRight: "5px"
                              },
                              attrs: {
                                placeholder: _vm.$t(
                                  "Please enter the question type"
                                ),
                                clearable: "",
                                size: "small"
                              },
                              model: {
                                value: _vm.wtlx,
                                callback: function($$v) {
                                  _vm.wtlx =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "wtlx"
                              }
                            },
                            _vm._l(_vm.typeList, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            "text-align": "right",
                            "align-self": "flex-end"
                          },
                          attrs: { xs: 24, sm: 8, md: 8, lg: 5, xl: 4 }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                title: _vm.$t("Refresh"),
                                type: "primary",
                                icon: "el-icon-search",
                                size: "mini",
                                plain: ""
                              },
                              on: { click: _vm.refreshChange }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("Refresh")) +
                                  "\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }